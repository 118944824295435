import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { StyledButton } from './styles';
import { Text } from '../Text';
import { Loader } from '../Loader';

interface IButton {
  children: any;
  iconLeft?: IconProp;
  iconRight?: IconProp | Element;
  size: 'small' | 'large';
  onClick?: (e?: object) => void;
  disabled?: boolean;
  className?: string;
  style?: {};
  loading?: boolean;
}

export const Button = ({
  children,
  iconLeft,
  iconRight,
  onClick,
  disabled = false,
  size = `small`,
  className,
  style,
  loading = false,
}: IButton) => {
  const disabledclass = disabled ? `button--disabled` : ``;
  return (
    <StyledButton
      style={style}
      role="button"
      size={size}
      className={`${disabledclass} ${className}`}
      onClick={!disabled ? onClick : null}
      iconLeft={iconLeft}
      iconRight={iconRight}
      disabled={disabled || loading}
    >
      {iconLeft ? (
        <FontAwesomeIcon
          className={`button__icon button__icon--left ${disabledclass}`}
          icon={iconLeft}
        />
      ) : null}
      {!loading ? (
        <Text
          noRhythem
          type="body3"
          tag="span"
          color={disabled ? `blue_1_30` : `white_1`}
        >
          {children}
        </Text>
      ) : (
        <Loader visible={loading} />
      )}
      {iconRight ? (
        <FontAwesomeIcon
          className={`button__icon button__icon--right ${disabledclass}`}
          icon={iconRight as IconProp}
        />
      ) : null}
    </StyledButton>
  );
};
