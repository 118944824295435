import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledButton {
  size: string;
  iconLeft: any;
  iconRight: any;
}

export const StyledButton = styled.button<IStyledButton>`
  padding: ${({ iconLeft, iconRight }) =>
    `0 ${iconLeft || iconRight ? rem(`32px`) : rem(`16px`)}`};
  height: ${({ theme, size }) => {
    const isSmallSize = size === `small`;
    return isSmallSize ? rem(`32px`) : `${theme.rhythmSizing(3)}rem`;
  }};
  border-radius: ${rem(`6px`)};
  background-image: linear-gradient(
    to left,
    ${({ theme }) => theme.colors.yellow_1},
    ${({ theme }) => theme.colors.yellow_3}
  );
  cursor: pointer;
  position: relative;
  z-index: 1;
  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: ${rem(`6px`)};
    background-image: linear-gradient(
      to left,
      ${({ theme }) => theme.colors.pink_2},
      ${({ theme }) => theme.colors.pink_1_150}
    );
    z-index: -1;
    transition: opacity 0.3s linear, background-image 0.3s linear;
    opacity: 0;
  }
  .body3 {
    transition: color 300ms ease-in-out;
    color: ${({ theme }) => theme.colors.blue_1};
  }
  &:hover {
    .body3 {
      color: ${({ theme }) => theme.colors.white_1};
    }
    &::before {
      opacity: 1;
    }
    & .button__icon {
      color: ${({ theme }) => theme.colors.white_1};
    }
  }
  & .button__icon {
    transition: color 300ms ease-in-out;
    color: ${({ theme }) => theme.colors.blue_1};
    &.button--disabled {
      color: ${({ theme }) => theme.colors.blue_1_30};
    }
  }
  & .button__icon--right {
    margin-left: ${rem(`8px`)};
  }
  & .button__icon--left {
    margin-right: ${rem(`8px`)};
  }

  .loading {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    padding: ${rem(`16px`)};
    vertical-align: middle;
    text-align: center;
    background-color: transparent;
    border: ${rem(`3px`)} solid transparent;
    border-top-color: ${({ theme }) => theme.colors.pink_1};
    border-bottom-color: ${({ theme }) => theme.colors.pink_1};
    border-radius: 50%;
  }
  .outer {
    animation: spin 1s infinite;
  }
  .inner {
    animation: spin 1s infinite;
  }
  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`;
