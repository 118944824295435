import styled from 'styled-components';

export const StyledEmployeeCard = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .employee__container {
    flex: 1;
    display: flex;
    padding: 12px;
    position: relative;
    overflow: hidden;
    background-color: ${({ theme }) => theme.colors.blue_1_65};
    border-radius: 8px;
  }
  .employee-layout__avatar {
    flex: 0;
  }
  .employee-layout__content {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 12px 0 12px 12px;
  }
  .employee-layout__content--left {
    flex: 1;
  }
  .employee-layout__content--right {
    flex: 0;
    padding-left: 12px;
  }
  .name {
    margin-bottom: 8px;
  }
  .positions {
    padding: 0 0 0 26px;
  }
  .positions__item {
    color: ${({ theme }) => theme.colors.gray_3_65};
  }
  .position {
    line-height: 20px;
  }
  .social {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .social__item {
    &:not(:first-child) {
      margin-top: 6px;
    }
    button {
      padding: 4px;
    }
    .body3 {
      font-size: 26px;
    }
  }
`;
