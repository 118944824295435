import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Text } from '../Text';
import { StyledEmployeeCard } from './styles';
import { Avatar } from '../Avatar';
import { Button } from '../Button';

interface IEmployeeCard {
  name: string;
  position: string[];
  imageUrl: any;
  alt?: string;
  className?: string;
  githubLink?: string;
  linkedInLink?: string;
}

export const EmployeeCard = ({
  name,
  position,
  imageUrl,
  alt,
  className,
  githubLink,
  linkedInLink,
}: IEmployeeCard) => (
  <StyledEmployeeCard className={className}>
    <div className="employee__container">
      <Avatar
        alt={alt}
        imageUrl={imageUrl}
        className="employee-layout__avatar"
        disableLink
        size="xl"
      />
      <div className="employee-layout__content">
        <div className="employee-layout__content--left">
          <Text noRhythem type="subtitle2" color="gray_3" className="name">
            {name}
          </Text>
          <ul className="positions">
            {position.map((pos) => (
              <li className="positions__item" key={pos}>
                <Text
                  noRhythem
                  type="body3"
                  color="gray_3_65"
                  className="position"
                >
                  {pos}
                </Text>
              </li>
            ))}
          </ul>
        </div>
        {githubLink || linkedInLink ? (
          <div className="social employee-layout__content--right">
            {githubLink && (
              <div className="social__item">
                <Link target="_blank" to={githubLink}>
                  <Button size="small" disabled={false}>
                    <FontAwesomeIcon
                      size="sm"
                      className="link__icon"
                      icon={faGithub}
                    />
                  </Button>
                </Link>
              </div>
            )}
            {linkedInLink && (
              <div className="social__item">
                <Link target="_blank" to={linkedInLink}>
                  <Button size="small" disabled={false}>
                    <FontAwesomeIcon
                      size="sm"
                      className="link__icon"
                      icon={faLinkedinIn}
                    />
                  </Button>
                </Link>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  </StyledEmployeeCard>
);
