import styled from 'styled-components';
import { TSizes } from './index';

interface IStyledAvatar {
  disableLink: any;
  theme: any;
  className: any;
  size: TSizes;
}

const stylesBySize = {
  xxs: { border: `1px`, container: 2, image: 1.66 },
  xs: { border: `1px`, container: 3, image: 2.5 },
  sm: { border: `1px`, container: 4, image: 3.33 },
  md: { border: `2px`, container: 6, image: 5 },
  lg: { border: `2px`, container: 7, image: 6 },
  xl: { border: `2px`, container: 8, image: 7 },
  xxl: { border: `2px`, container: 10, image: 8.75 },
};

export const StyledAvatar = styled.div.attrs(
  ({ theme, size }: IStyledAvatar) => {
    const { border, container, image } = stylesBySize[size];
    return {
      containerHeight: theme.rhythmSizing(container),
      containerWidth: theme.rhythmSizing(container),
      imageHeight: theme.rhythmSizing(image),
      imageWidth: theme.rhythmSizing(image),
      borderWidth: border,
    };
  },
)<IStyledAvatar>`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  /* width: ${({ containerWidth }) => containerWidth}rem; */
  ${({ theme, disableLink, borderWidth }) => {
    if (disableLink) {
      return null;
    }
    return theme.breakpoints.desktop`
        &:hover {
          .avatar__text {
            color: ${theme.colors.pink_2};
          }
          .avatar__img {
            transition: filter 300ms ease-in-out;
            filter: brightness(115%);
          }
          .avatar__outer-lines,
          .avatar__inner-lines {
            &::before,
            &::after {
              transition: border-color 300ms ease-in-out;
              border: ${borderWidth} solid ${theme.colors.yellow_1};
            }
            &::before {
              top: 0;
              left: 0;
              border-radius: 100% 0 0 0;
              border-bottom: 0;
              border-right: 0;
            }
            &::after {
              bottom: 0;
              right: 0;
              border-radius: 0 0 100% 0;
              border-top: 0;
              border-left: 0;
            }
          }
        }
  `;
  }}
  .avatar__outer-lines,
  .avatar__inner-lines {
    display: flex;
    overflow: visible;
    align-items: center;
    justify-content: center;
    position: relative;
    height: ${({ containerHeight }) => containerHeight}rem;
    width: ${({ containerWidth }) => containerWidth}rem;
    &::before,
    &::after {
      content: '';
      height: ${({ containerHeight }) => containerHeight / 2}rem;
      width: ${({ containerWidth }) => containerWidth / 2}rem;
      position: absolute;
      border: ${({ borderWidth }) => borderWidth} solid
        ${({ theme }) => theme.colors.pink_2};
      z-index: 99;
      transition: border-color 300ms ease-in-out;
      border: ${({ borderWidth }) => borderWidth} solid
        ${({ theme }) => theme.colors.pink_2};
    }
    &::before {
      top: 0;
      left: 0;
      border-radius: 100% 0 0 0;
      border-bottom: 0;
      border-right: 0;
    }
    &::after {
      bottom: 0;
      right: 0;
      border-radius: 0 0 100% 0;
      border-top: 0;
      border-left: 0;
    }
  }
  .avatar__outer-lines {
    transform: rotate(-45deg);
  }
  .avatar__inner-lines {
    transform: rotate(45deg);
  }
  a {
    display: flex;
    flex: 0 1 auto;
    justify-content: center;
  }
  .avatar__container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: ${({ imageHeight }) => imageHeight}rem;
    width: ${({ imageWidth }) => imageWidth}rem;
    border-radius: 50%;
  }
  .avatar__img {
    object-fit: cover;
    height: inherit;
    width: inherit;
    object-position: 50% 50%;
    transition: filter 300ms ease-in-out;
  }
  .avatar__text {
    text-align: center;
    line-height: 21px !important;
    margin-left: 12px;
  }
`;
