import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Text } from '../Text';
import { StyledAvatar } from './styles';

export type TSizes = 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

interface IAvatar {
  imageUrl: any;
  alt?: string;
  className?: string;
  linkTo?: string;
  bottomText?: string;
  disableLink?: boolean;
  size?: TSizes;
}

const Content = ({ children, linkTo, disableLink }) =>
  disableLink || !linkTo ? (
    <div className="avatar__outer-lines">
      <div className="avatar__inner-lines">
        <div className="avatar__container">{children}</div>
      </div>
    </div>
  ) : (
    <Link to={linkTo}>
      <div className="avatar__outer-lines">
        <div className="avatar__inner-lines">
          <div className="avatar__container">{children}</div>
        </div>
      </div>
    </Link>
  );

export const Avatar = ({
  imageUrl,
  alt,
  className,
  linkTo,
  disableLink = false,
  bottomText,
  size = `md`,
}: IAvatar) => (
  <StyledAvatar className={className} disableLink={disableLink} size={size}>
    <Content disableLink={disableLink} linkTo={linkTo}>
      <GatsbyImage image={imageUrl} className="avatar__img" alt={alt} />
    </Content>

    {bottomText && (
      <Text
        noRhythem
        type="subtitle3"
        tag="span"
        color="gray_3"
        className="avatar__text"
      >
        {bottomText}
      </Text>
    )}
  </StyledAvatar>
);
