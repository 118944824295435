import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { StyledLoader } from './styles';

export interface ILoader {
  className?: string;
  visible?: boolean;
}
export const Loader = ({ className, visible = false }: ILoader) => {
  return (
    visible && (
      <StyledLoader className={className}>
        <FontAwesomeIcon icon={faSpinner} />
      </StyledLoader>
    )
  );
};
