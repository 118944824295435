import styled from 'styled-components';
import { rem } from 'polished';

interface IStyledAbout {
  theme: any;
}

export const StyledAbout = styled.div<IStyledAbout>`
  .header {
    margin-bottom: ${rem(`190px`)};
  }
  .team-subtitle {
    margin-bottom: ${rem(`42px`)};
  }
  .team-members {
    display: grid;
    grid-auto-columns: min-content;
    grid-auto-flow: dense;
    grid-gap: ${rem(`24px`)};
    align-items: center;
    margin-top: ${rem(`12px`)};
    grid-template-columns: repeat(2, 1fr);
    ${({ theme }) => theme.breakpoints.watch`
      grid-template-columns: repeat(1, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.phone`
      grid-template-columns: repeat(2, 1fr);
    `}
    ${({ theme }) => theme.breakpoints.tablet`
      grid-template-columns: repeat(3, 1fr);
    `}
  }
`;
