import React from 'react';
import { graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';
import { get } from 'lodash';
import { getImage } from 'gatsby-plugin-image';
import { Social } from '@/components/Social';
import { EmployeeCard } from '@/components/EmployeeCard';
import Layout, { Main } from '../../components/Layout';
import { Content } from '../../components/Content';
import { Text } from '../../components/Text';
import { MdContent } from '../../components/MdContent';
import { Seo } from '../../components/SEO';
import { CallToAction } from '../../components/CallToAction';
import { StyledAbout } from './styles';

interface IAboutPageTemplate {
  content: any;
  teamMembers: any;
}

interface IAboutPage {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    contentfulPage: any;
    allContentfulPerson: any;
  };
  location: object;
}

const AboutPageTemplate = ({ content, teamMembers }: IAboutPageTemplate) => {
  const {
    title,
    subtitle,
    body,
    components,
    ctaTitle,
    ctaHookId,
    ctaIcon,
    ctaBody,
  } = content;
  const { team } = components;
  const { body: mdBody } = body;

  return (
    <>
      <Content restrictWidth setMaxWidth="75%">
        <Text type="title1" tag="h2" color="white_1">
          {title}
        </Text>
        <Text type="subtitle1" tag="h3" color="white_1_45">
          {subtitle}
        </Text>
        <Text type="body1" tag="div" color="gray_3">
          <MdContent content={mdBody} />
        </Text>
      </Content>

      <Content restrictWidth setMaxWidth="75%" className="last">
        <Text type="title3" tag="h3" color="gray_3">
          {team.title}
        </Text>
        <Text
          type="subtitle2"
          tag="h3"
          color="white_1_45"
          className="team-subtitle"
        >
          {team.subtitle}
        </Text>
        <div className="team-members">
          {teamMembers.edges.map(({ node }) => {
            const {
              name,
              role,
              image: nodeImage,
              githubPortfolio,
              linkedInPortfolio,
            } = node;
            const image = getImage(nodeImage);
            return (
              <EmployeeCard
                key={uuidv4()}
                name={name}
                position={role}
                className="team-member"
                imageUrl={image}
                alt={`Image of ${name}`}
                githubLink={githubPortfolio}
                linkedInLink={linkedInPortfolio}
              />
            );
          })}
        </div>
      </Content>

      <CallToAction
        hookId={ctaHookId}
        icon={ctaIcon}
        title={ctaTitle}
        body={ctaBody.ctaBody}
      />
      <Social />
    </>
  );
};

const AboutPageDesktopTemplate = ({
  content,
  teamMembers,
}: IAboutPageTemplate) => {
  const { components } = content;
  const { team } = components;

  return (
    <div className="body">
      <Content noMargin>
        <Text type="title3" tag="h3" color="gray_3">
          {team.title}
        </Text>
        <Text
          type="subtitle2"
          tag="h3"
          color="white_1_45"
          className="team-subtitle"
        >
          {team.subtitle}
        </Text>
        <div className="team-members">
          {teamMembers.edges.map(({ node }) => {
            const {
              role,
              name,
              image: nodeImage,
              githubPortfolio,
              linkedInPortfolio,
            } = node;
            const image = getImage(nodeImage);
            return (
              <EmployeeCard
                key={uuidv4()}
                name={name}
                position={role}
                className="team-member"
                imageUrl={image}
                alt={`Image of ${name}`}
                githubLink={githubPortfolio}
                linkedInLink={linkedInPortfolio}
              />
            );
          })}
        </div>
      </Content>
    </div>
  );
};

const AboutPage = ({ data, location }: IAboutPage) => {
  const allTeamMembers = get(data, `allContentfulPerson`);
  const page = get(data, `contentfulPage`);
  const {
    title,
    subtitle,
    body,
    slug,
    metaDescription,
    metaTitle,
    templateKey,
    heroImage,
    svgTopPath,
    svgBottomPath,
    components,
    ctaTitle,
    ctaHookId,
    ctaIcon: pageCTAIcon,
    ctaBody,
  } = page;
  const hero = getImage(heroImage);
  const ctaIcon = getImage(pageCTAIcon);

  return (
    <StyledAbout>
      <Layout
        location={location}
        coverImage={hero}
        topSvg={svgTopPath.svg.content}
        bottomSvg={svgBottomPath.svg.content}
        variant={2}
        desktopHeading={
          <Content restrictWidth setMaxWidth="90%" noMargin>
            <Text type="title1" tag="h2" color="white_1" className="header">
              {title}
            </Text>
            <Text type="subtitle1" tag="h3" color="white_1_45">
              {subtitle}
            </Text>
            <Text type="body1" tag="div" color="gray_3">
              <MdContent content={body.body} />
            </Text>
          </Content>
        }
        desktopBody={
          <AboutPageDesktopTemplate
            content={{
              components,
            }}
            teamMembers={allTeamMembers}
          />
        }
        desktopCta={
          <CallToAction
            hookId={ctaHookId}
            icon={ctaIcon}
            title={ctaTitle}
            body={ctaBody.ctaBody}
            maxWidth="100%"
          />
        }
      >
        <Seo
          title={title}
          metaTitle={metaTitle}
          metaDesc={metaDescription}
          cover={hero}
          slug={slug}
          templateKey={templateKey}
        />
        <Main background="transparent" disableVertPadding={false}>
          <AboutPageTemplate
            content={{
              title,
              subtitle,
              body,
              components,
              ctaTitle,
              ctaHookId,
              ctaIcon,
              ctaBody,
            }}
            teamMembers={allTeamMembers}
          />
        </Main>
      </Layout>
    </StyledAbout>
  );
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    contentfulPage(slug: { eq: $slug }) {
      templateKey
      title
      subtitle
      slug
      body {
        body
      }
      components {
        team {
          title
          subtitle
        }
      }
      heroImage {
        gatsbyImageData(
          width: 560
          height: 900
          quality: 85
          placeholder: BLURRED
        )
      }
      svgTopPath {
        svg {
          content
        }
      }
      svgBottomPath {
        svg {
          content
        }
      }
      metaTitle
      metaDescription
      ctaTitle
      ctaHookId
      ctaIcon {
        gatsbyImageData(width: 48, quality: 85, placeholder: BLURRED)
      }
      ctaBody {
        ctaBody
      }
    }
    allContentfulPerson {
      edges {
        node {
          image {
            gatsbyImageData(
              width: 72
              height: 72
              quality: 85
              placeholder: BLURRED
            )
          }
          name
          role
          slug
          githubPortfolio
          linkedInPortfolio
        }
      }
    }
  }
`;
